<template>
    <div class="building-container">
        <div class="building-content">
            <h2>
                {{title}}
            </h2>
            <h6 v-if="false">
                {{description}}
            </h6>
        </div>
        <div class="building-amenities">
            <vueper-slides
                :bullets="false"
                ref="vueperslides1"
                id="mainSlides"
                :slide-ratio="1 / 4"
                :dragging-distance="70"
                :fixed-height="adaptiveHeight"
                prevent-y-scroll>
                <vueper-slide
                    v-for="(slide, i) in amenities"
                    :key="i"
                    :image="url+slide.url"
                    class="main_slides"
                    >
                </vueper-slide>
            </vueper-slides>
        </div>
        <div class="building-exploration">
            <router-link :to="`/building/${slug}`" class="btn">
                Explore
            </router-link>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "FeatureBuilding",
    components: {
        VueperSlides, 
        VueperSlide,
    },
    props: ['title','description', 'amenities', 'slug'],
    data(){
        return{
            url: 'https://strapi.uptowncreative.io',
            window: {
                width: 0,
                height: 0
            },
        }
    },
    filters: {
        priceFormat: function (value) {
            if (!value) return ''
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            return formatter.format(value)
        }
    },
    computed: {
        adaptiveHeight: function(){
            if (this.window.width < 1100 & this.window.height > 1000){
                return '40vh';
            }
            if (this.window.width > 576){
                return '80vh';
            } else {
                return '300px';
            }
        },
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss" scoped>
.building-container{
    margin: 4rem auto;
}
.building-content{
    text-align: center;
    h6{
        margin: 2rem auto;
        max-width: 280px;
    }
}
.building-amenities{
    margin: 2rem;
}
.building-exploration{
    text-align: center;
    margin: 0 auto;
}
@media (max-width: $mobile) {
    .building-amenities{
        margin: 2rem 0;
    }
}
</style>

<style lang="scss">
.vueperslides__arrow--prev{
    left: 1.5em;
}
.vueperslides__arrow--next{
    right: 1.5em;
}

@media (max-width: $mobile) {
    .vueperslides__arrow--prev{
        left: 0;
    }
    .vueperslides__arrow--next{
        right: 0;
    }
}

</style>
<template>
    <div id="property">
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div class="property-container" v-else>
            <hr class="menu-separator">
            <div class="page-title">
                <h2>
                    {{data.Title}}
                </h2>
            </div>
            <div id="slides">
                <span v-if="data.Tag" class="tag">{{data.Tag | startCase}}</span>
                <vueper-slides
                        :bullets="false"
                        @slide="activeThumbnail"
                        ref="vueperslides1"
                        id="mainSlides"
                        class="no-shadow"
                        :slide-ratio="1 / 4"
                        :dragging-distance="70"
                        :fixed-height="adaptiveHeight"
                        prevent-y-scroll>
                    <vueper-slide
                        v-for="(slide, i) in data.Carousel"
                        :key="i"
                        :image="url+slide.url"
                        class="main_slides"
                        >
                    </vueper-slide>
                </vueper-slides>
            </div>
            <div class="property-content">
                <div class="property-data-container">
                    <div class="property-data">
                        <h6>
                            {{data.Price | priceFormat}} <span v-if="data.Status === 'lease'">/mo.</span>
                        </h6>
                        <h6 class="bordered-top">
                            {{data.Beds}} Beds
                        </h6>
                        <h6 class="bordered-top">
                            {{data.Baths}} Baths
                        </h6>
                        <h6 class="bordered-top">
                            {{data.Size}} Sqft
                        </h6>
                    </div>
                    <div class="splatter-bottom">
                        <img src="/images/splatter-bottom.png" alt="splatter style">
                    </div>
                </div>
                <div v-bind:class="{description: descriptionLength > 1500}" class="property-description">
                    <p class="address">
                        {{data.Address}},<br>
                        {{data.Area}},<br>
                        {{data.State}} {{data.Zip}}

                    </p>
                    <p v-html="data.Description">
                        {{data.Description}}
                    </p>
                </div>
            </div>
            <div v-if="data.Video" class='embed-container'>
                <iframe width="100%" :src="'https://www.youtube.com/embed/'+ data.Video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div v-if="data.VimeoVideoID" class='embed-container'>
                <iframe width="100%" :src="'https://player.vimeo.com/video/'+ data.VimeoVideoID" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <FeatureBuilding
                v-if="buildingData"
                :title="buildingData.Title "
                :description="buildingData.ShortDescription"
                :amenities="buildingData.Amenities"
                :slug="buildingData.Title"
                />
        </div>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import { VueperSlides, VueperSlide } from 'vueperslides'
import FeatureBuilding from '../components/FeaturedBuilding.vue'

export default {
    name: "property",
    components: {
        Spinner,
        VueperSlides, 
        VueperSlide,
        FeatureBuilding
    },
    data(){
        return{
            title: "Property",
            currentIndex: 0,
            loading: true,
            nodatafound: false,
            data: {},
            url: 'https://strapi.uptowncreative.io',
            images:[],
            window: {
                width: 0,
                height: 0
            },
            buildingData: {}
        }
    },
    filters: {
        priceFormat: function (value) {
            if (!value) return ''
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            return formatter.format(value)
        }
    },
    computed: {
        adaptiveHeight: function(){
            if (this.window.width < 1100 & this.window.height > 1000){
                return '40vh';
            }
            if (this.window.width > 576){
                return '80vh';
            } else {
                return '300px';
            }
        },
        headerImage: function(){
            if (this.data.HeaderImage){
                return this.url+ this.data.HeaderImage.url
            } else if(this.data.DisplayImage){
                return this.url + this.data.DisplayImage.url
            }
            return '';
        },
        descriptionLength: function(){
            return this.data.Description.length;
        }
    },
    methods: {
        activeThumbnail(event){
            this.$refs.vueperslides2.goToSlide(event.currentSlide.index, { emit: false })
            this.currentIndex = event.currentSlide.index;

        },
        activeSlide(event){
            this.$refs.vueperslides1.goToSlide(event.currentSlide.index, { emit: false })
            this.currentIndex = event.currentSlide.index;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        toggleModal() {
            this.$modal.show('example');
        },
        turnOffModal() {
            this.$modal.hide('example');
        }
    },
    mounted:function(){
        this.axios.get(`https://strapi.uptowncreative.io/coastal-luxe-properties?Slug=${this.$route.params.slug}`)
        .then((response) => {
            this.data = response.data[0];
            if(this.data.hasOwnProperty('coastal_luxe_building')){
                this.buildingData = this.data.coastal_luxe_building
            }
            this.loading = false;
        })
    },
    metaInfo() {
         let data = this.data.Title; 
         return {
             title: data ? data : "Property",
             titleTemplate: '%s | Coastal Luxe',
             meta: [{
                name: 'description', 
                content: "Coastal Luxe"
            }]
         }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss" scoped>
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin: 2rem 0;
}
		
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.property-container{
    margin-bottom: 6rem;
}
.title-container{
    text-align: right;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background: #1a1a1a;
    text-transform: uppercase;
    height: 20vh;
}
#slides{
    position: relative;
}
.floor-plan-container{
    position: absolute;
    bottom: 100px;
    z-index: 2;
    display: flex;
    align-items: center;
    background: #000;
    text-transform: uppercase;
    padding: 8px 15px;
    left: 50%;
    transform:translateX(-50%);
    .floorplan-image{
        display: inline-block;
        width: 25px;
        margin-left: 20px;
    }
}
.thumbnails{
    margin-bottom: 1rem;
    .vueperslide{
        padding: 0 20px;
        z-index: 10;
        padding-top: 10px;
    }
}
.thumbnail-slide{
    padding-top: 10px;
    img{
        width: 80px;
        height: 60px;
        object-fit: cover;
    }
}
.property-content{
    display: flex;
    align-items: stretch;
}
.property-description{
    width: 70%;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .address{
        margin: 0;
        margin-bottom: 3rem;
    }
}
.property-data-container{
    flex: 1 1;
}
.property-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f1f3f7;
    padding: 8rem 0;
    h6{
        text-align: center;
        padding: 1rem 0;
        margin: 0 auto;
        width: 150px;
        &.bordered-top{
            border-top: 1px solid #000;
        }
    }
}
.description{
    margin-top: 48px;
}

@media (max-width: 1200px) {
    .property-data{
        padding: 6rem 0;
    }
}
@media (max-width: $tablet) {
    hr{
        display: none;
    }
    .page-title{
        h2{
            font-size: 30px;
            margin: 0 2rem;
        }
    }
    .title-container{
        padding: 3rem 2rem;
    }
    .property-content{
        margin: 0;
        flex-wrap: wrap;
        flex-direction: column;
        .property-data{
            margin: 0;
            width: 100%;
            flex:unset;
        }
    }
    .property-description{
        width: 100%;
        padding-right: unset;
        border-right: unset;
        padding: 0;
        padding: 2rem;
        text-align: center;
        h2{
            font-weight: 100;
            letter-spacing: 2px;
            font-size: 40px;
            text-transform: uppercase;
        }
        .address{
            margin: 1rem 0;
        }
    }
    .property-data{
        padding: 3rem 0;
        h3{
            letter-spacing: 3px;
            font-weight: 500;
            font-size: 18px;
        }
    }
}
</style>